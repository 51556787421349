$(document).ready(function(){
  $(document).on('click', '#btn-file-import', function(){
    var $form = $('#file-import');

    var fdo = new FormData($form.get(0));

    $.ajax({
      type: "POST",
      url: "/business_inventory_analysis_lists/analysis_data_import",
      data: fdo, 
      processData: false,
      contentType: false
    })
    .done(function(data) {

      $('#file-import').find(':text').val("");

      $('form').find(':text').val("");
      $('form').find('input[type="number"]').val("");
      $('form').find('textarea').val("");

      $('.business_inventory_analyses tr').each(function(index , elm){
        $(elm).find('input.primary_item').val(`${data[index].primary_item}`);
        $(elm).find('input.secondary_item').val(`${data[index].secondary_item}`);
        $(elm).find('textarea.detail').val(`${data[index].detail}`);
        $(elm).find('input.usage_system').val(`${data[index].usage_system}`)
        $(elm).find('select.telework').val(`${data[index].telework}`);
        $(elm).find('select.difficulty').val(`${data[index].difficulty}`);
        $(elm).find('select.responsibility').val(`${data[index].responsibility}`);
        $(elm).find('input.number').val(`${data[index].number}`);
        $(elm).find('input.required_time').val(`${data[index].required_time}`);
        $(elm).find('input.operation').val(`${data[index].operation}`);
        $(elm).find('textarea.operation_detail').val(`${data[index].operation_detail}`);
      });
    })
    .fail(function(){
    })
  });

  $(document).on('click', '#import', function(){
    var year = $('#year').val();
    var month = $('#month').val();

    var postdata = {"year": year, "month":month};

    $.ajax({
      type: "POST",
      url: "/business_inventory_analysis_lists/work_hours_achivement_import",
      data: postdata
    })
    .done(function(data) {
      $('#file-import').find(':text').val("");

      $('form').find(':text').val("");
      $('form').find('input[type="number"]').val("");
      $('form').find('textarea').val("");
      $('form').find('select').val("");

      $('.business_inventory_analyses tr').each(function(index , elm){
        $(elm).find('input.primary_item').val(`${data[index].primary_item}`);
        $(elm).find('input.secondary_item').val(`${data[index].secondary_item}`);
        $(elm).find('textarea.detail').val(`${data[index].detail}`);
        $(elm).find('input.number').val(`${data[index].number}`);
        $(elm).find('input.required_time').val(`${data[index].required_time}`);
      });
    })
    .fail(function(){
      console("Failure");
    })
  });
});

$(document).on('turbolinks:load', function(){
  var total_number        = 0;
  $('.business_inventory_analyses tr').each(function(index , elm){
    var number        = Number($(elm).find('input.number').val());
    var required_time = Number($(elm).find('input.required_time').val());

    total = number * required_time

    total_number += total;
  });

  $('.business_inventory_analyses tr').each(function(index , elm){
    var number = "";
    var required_time = "";
    var difference = "";
    var difference_total = 0;
    var total = 0;

    number        = Number($(elm).find('input.number').val());
    required_time = Number($(elm).find('input.required_time').val());
    difference = Number($(elm).find('input.difference').val());

    if(difference !== ""){
      difference *= 60
    }

    total = number * required_time

    total_average = total / total_number * 100

    total_average = Math.round(total_average*10) / 10;
    if(isNaN(total_average)){
      total_average = "0"
    }

    var hourly_wage = $('.hourly_wage').val();
    var total_hourly_wage = 0; 

    total_hourly_wage = total / 60 * hourly_wage
    if(isNaN(total_hourly_wage)){
      total_hourly_wage = "0"
    }  else {
      total_hourly_wage = total_hourly_wage.toLocaleString()
    }
    
    difference_total = total + difference;

    $(this).children('td.total').text(total);
    $(this).children('td.average').text(total_average + "%");
    $(this).children('td.total_hourly_wage').text("¥ " + total_hourly_wage);

    $(this).children('td.difference_total').text(difference_total);
  });


  // 20211014
  calc_summary_by_category();
  $('input.number').change(function() {
      calc_worktime();
  });

var others_total_number = Number($('.others_total').text());
var others_difference = Number($('input.others_difference').val());
var others_difference_total = "";
if(others_difference !== ""){
  others_difference *= 60
} else {
  others_difference = 0
}
others_difference_total = others_total_number + others_difference;

$('.others_difference_total').text(others_difference_total);
});


function AddCategory(number, key, achievement_val, ratio_val) {
  let html = `<tr>
                <td>${ number + 1}</td>
                <td>${ key }</td>
                <td>${ achievement_val }</td>
                <td>${ ratio_val }%</td>
             </tr>`
  return html;
}

function AddOthersCategory(name, others_number, others_ratio_val) {
  let html = `<tr>
                <td></td>
                <td>${ name }</td>
                <td>${ others_number }</td>
                <td>${ others_ratio_val }%</td>
             </tr>`
  return html;
}

function AddTotalCategory(achievement_val) {
  let html = `<tr>
                <td></td>
                <td>合計</td>
                <td>${ achievement_val }</td>
                <td>100%</td>
             </tr>`
  return html;
}
// 20211014
function calc_summary_by_category(){
  var data  = {};
  var total = 0;
  var categories = $("table > tbody > tr > td.fixed01 > input");
  var work_time  = $("table > tbody > tr > td.total");

  var standard_working_hours = $("#standard_working_hours").val();
  var standard_working_hours_minutes = standard_working_hours * 60

  var key = "";
  for (let i = 0; i < categories.length; i++) {
    key = $(categories[i]).val();
    if (key == ""){continue;}
    if (key in data) {
      // nothing to do
    } else {
      data[key] = 0;
    }
    data[key] += parseInt($(work_time[i]).text());
    total     += parseInt($(work_time[i]).text());
  }
  var rows = "";
  var total_achievement_val = 0;
  Object.keys(data).forEach(function(key, i){
      var achievement_val = parseFloat((data[key] / 60).toFixed(1));
      total_achievement_val += achievement_val
      var ratio_val = parseFloat(data[key] / standard_working_hours_minutes * 100).toFixed(1);
      rows += AddCategory(i, key, achievement_val, ratio_val);
  });
  var others_total_number = standard_working_hours - total_achievement_val
  var others_ratio_val = parseFloat(others_total_number / standard_working_hours * 100).toFixed(1);
  total_achievement_val += others_total_number
  var others_total_number_minutes = (others_total_number * 60).toFixed(1)

  $('tbody.category_total tr').remove();
  $('tbody.category_total').append(rows);
  $('tbody.category_total').append(AddOthersCategory("その他", others_total_number, others_ratio_val));
  $('tbody.category_total').append(AddTotalCategory(total_achievement_val.toFixed(1)));

  $('.others_total').text(others_total_number_minutes);
  $('.others_average').text(others_ratio_val + "%");
}

// 20211014
function calc_worktime(){
  var numbers       = $("table > tbody > tr > td:nth-child(8) > input");
  var required_time = $("table > tbody > tr > td:nth-child(9) > input");
  var work_time     = $("table > tbody > tr > td.total");
  var time          = "";
  for (let i = 0; i < work_time.length; i++) {
    if ($(numbers[i]).val() != "" && $(required_time[i]).val() != "" ){
      total = (parseFloat($(numbers[i]).val()) * parseFloat($(required_time[i]).val()));

      $(work_time[i]).text(total);
    }
  }
  calc_summary_by_category();
}
