$(document).ready(function(){
  var total_achivement_time = 0;
  var total_target_time = 0;
  $('.monthly_work_hours tr').each(function(index , elm){
    var number = "";
    var required_time = "";
    var achivement_number = "";
    var achivement_required_time = "";
    var total_time = 0;
    var achivement_rate = 0;

    number = Number($(elm).find('.target_number').text().replace(/[^0-9]/g, ''));
    required_time = Number($(elm).find('.target_required_time').text().replace(/[^0-9]/g, ''));

    score = number * required_time 
    score = score / 60
    total_target_time += score

    achivement_number = Number($(elm).find('input.achivement_number').val());
    achivement_required_time = Number($(elm).find('input.achivement_required_time').val());

    if(isNaN(achivement_number)){
      achivement_number = Number($(elm).find('input.others_achivement_number').val());
      achivement_required_time = Number($(elm).find('input.others_achivement_required_time').val());
    }

    total_time = achivement_number * achivement_required_time

    total_achivement_time += total_time
    total_time = total_time / 60
    achivement_rate = total_time / score * 100

    
    if(achivement_rate == Infinity){
      achivement_rate = 0
    } else {
      achivement_rate = Math.round(achivement_rate, 1);
    }
    total_time = Number(total_time.toFixed(1));
    score = Number(score.toFixed(1));

    $(this).children('td.monthly_total').text(String(total_time) + "h");
    $(this).children('td.target_total').text(String(score) + "h");
    $(this).children('td.achivement_rate').text(achivement_rate + "%");
  });

  var monthly_target_number = $('.month-target-number').val();
  var others_ratio = $('.others_ratio').val();
  if(!others_ratio){
    others_ratio = 0;
  }
  others_number = monthly_target_number / 100 * others_ratio
  var others_target_number = 0;
  $('.monthly_work_hours tr').each(function(index , elm){
    var achivement_number = "";
    var achivement_required_time = "";
    var total_time = 0;
    var achivement_rate = 0;
    var monthly_man_hours_minutes = 0;
    var others_target_total = 0;
    var others_target_average = 0;
    var ratio = 0;
    var flag = 0;

    achivement_number = Number($(elm).find('input.achivement_number').val());
    achivement_required_time = Number($(elm).find('input.achivement_required_time').val());

    if(isNaN(achivement_number)){
      achivement_number = Number($(elm).find('input.others_achivement_number').val());
      achivement_required_time = Number($(elm).find('input.others_achivement_required_time').val());
      flag = 1;
    }

    total_time = achivement_number * achivement_required_time

    ratio = total_time / total_achivement_time * 100

    monthly_man_hours_minutes = Number(monthly_target_number) * 60;
    others_target_number = others_number

    if(ratio == Infinity){
      ratio = 0
    } else {
      ratio = Math.round(ratio, 1);
      if(isNaN(ratio)){
        ratio = 0
      }
    }
    if(flag == 1){
      $('.others_achivement_total').text(total_time / 60);
      $(this).children('td.others_ratio').text(ratio + "%");
    } else {
      $(this).children('td.ratio').text(ratio + "%");
    }
    if(monthly_target_number == "0"){
      others_target_total = 0
    }

    if(others_target_average == "-Infinity"){
      others_target_average = 0
    }
    $('.others_target_total').text(others_target_number + "h");
    $('.others_target_totalling').text(others_number);
    $('.others_target_average').text(others_ratio + "%");
  });

  var others_total = $('.others_target_total').text().replace(/[^0-9]/g, '');

  var others_achivement_number = $('input.others_achivement_number').val();
  var others_achivement_required_time = $('input.others_achivement_required_time').val();

  var others_total_val = Number(others_achivement_number * others_achivement_required_time)
  var others_monthly_total = others_total_val / 60
  others_total = others_total * 60;
  var others_average_rate = Math.round(others_total_val / others_total * 100, 1);

  if(isNaN(others_average_rate)){
    others_average_rate = 0
  }
  if(others_average_rate == Infinity){
    others_average_rate = 0
  }
  others_monthly_total = Number(others_monthly_total.toFixed(1));
  $('.others_monthly_total').text(String(others_monthly_total) + "h");
  $('.others_achivement_rate_2').text(others_average_rate + "%");

  var achivement_sum_total = $('.achivement-sum-total').text();
  var others_achivement_total = $('.others_achivement_total').text();
  var others_achivement_average = others_achivement_total / achivement_sum_total * 100;
  var others_achivement_rate = others_achivement_total / monthly_target_number * 100;
  others_achivement_average = Math.round(others_achivement_average*10) / 10;
  others_achivement_rate = Math.round(others_achivement_rate*10) / 10;

  if(isNaN(others_achivement_average)){ others_achivement_average = 0 }
  if(isNaN(others_achivement_rate)){ 
    others_achivement_rate = 0 
  } else if(others_achivement_rate == Infinity){
    others_achivement_rate = 0 
  } else {

  }

 $('.others_achivement_average').text(others_achivement_average + "%");
 $('.others_achivement_rate').text(others_achivement_rate + "%");

  $('.work-hours-achivements tr').each(function(index, elm){
    var target_total = $('.target-sum-total').text();
    var achivement_total = $('.achivement-sum-total').text();

    var target_number   = $(this).children('td.target-total').text();
    var achivement_number   = $(this).children('td.achivement-total').text();

    var total_target_average = target_number / target_total * 100
    total_target_average = Math.round(total_target_average*10) / 10;
    if(isNaN(total_target_average)){
      total_target_average = 0;
    } else if(total_target_average == Infinity){
      total_target_average = "--";
    }

    if(Number(achivement_total) != 0){
      var total_achivement_average = achivement_number / achivement_total * 100
      total_achivement_average = Math.round(total_achivement_average*10) / 10;
    } else {
      var total_achivement_average = 0
    }
    var achivement_rate = achivement_number / target_number * 100
    achivement_rate = Math.round(achivement_rate*10) / 10;

    $(this).children('td.target-average').text(total_target_average + "%");
    $(this).children('td.achivement-average').text(total_achivement_average + "%");
    $(this).children('td.achievement-rate').text(achivement_rate + "%");

    $('.achivement').text()
  });

  var month_target_number     = $('.month-target-number').val();
  var month_achivement_number = $('.month-achivement-number').text();
  var achivement_difference = month_achivement_number - month_target_number

  achivement_difference = Math.round(achivement_difference*10) / 10;
  $('.achivement-difference').text(achivement_difference);

  var target_sum_total = $('.target-sum-total').text();
  var achivement_sum_total = $('.achivement-sum-total').text();

  var achivement_sum_total = achivement_sum_total / target_sum_total * 100

  if(isNaN(achivement_sum_total)){
    achivement_sum_total = 0
  }
  $('.achievement-sum-rate').text(`${achivement_sum_total.toFixed(1)}%`);
});