$(document).ready(function(){

  var monday_score = 0;
  var tuesday_score = 0;
  var wednesday_score = 0;
  var thursday_score = 0;
  var friday_score = 0;
  var saturday_score = 0;
  var sunday_score = 0;
  var total = 0;
  $('.monthly_work_hours tr').each(function(index , elm){

    for (i = 0; i < 7; i++) {
      var number = 0; 
      var required_time = 0; 
      var score = 0;

      number = Number($(elm).find(`input[class$=${i}_number]`).val());
      required_time = Number($(elm).find(`input[class$=${i}_required_time]`).val());

      score = number * required_time 
      if(isNaN(score)){
        score = 0;
      }
      $(this).children(`td.${i}_total`).text(score + "分");

      if(i == 0){
        monday_score += score
      }  else if(i == 1){
        tuesday_score += score
      }  else if(i == 2){
        wednesday_score += score
      }  else if(i == 3){
        thursday_score += score
      }  else if(i == 4){
        friday_score += score
      }  else if(i == 5){
        saturday_score += score
      }  else if(i == 6){
        sunday_score += score
      } else {
        // nothing to do
      }
    }
    var item_total = 0;
    item_total = Number($(elm).find('td.item_total').text());
    if(isNaN(item_total)){
      item_total = 0
    }

    total += item_total
  });

  for (i = 0; i < 7; i++) {
    var others_number = 0; 
    var others_required_time = 0; 
    var others_score = 0;

    others_number = Number($(`input.${i}_others_number`).val());
    others_required_time = Number($(`input.${i}_others_required_time`).val());
    others_score = others_number * others_required_time 

    if(isNaN(others_score)){
      others_score = 0;
    }
    $(`td.${i}_others_total`).text(others_score + "分");

    if(i == 0){
      monday_score += others_score
    }  else if(i == 1){
      tuesday_score += others_score
    }  else if(i == 2){
      wednesday_score += others_score
    }  else if(i == 3){
      thursday_score += others_score
    }  else if(i == 4){
      friday_score += others_score
    }  else if(i == 5){
      saturday_score += others_score
    }  else if(i == 6){
      sunday_score += others_score
    } else {
      // nothing to do
    }
  }

  
  $('td.total_0').text(minutes_to_hours(monday_score)+ "h");
  $('td.total_1').text(minutes_to_hours(tuesday_score) + "h");
  $('td.total_2').text(minutes_to_hours(wednesday_score) + "h");
  $('td.total_3').text(minutes_to_hours(thursday_score) + "h");
  $('td.total_4').text(minutes_to_hours(friday_score) + "h");
  $('td.total_5').text(minutes_to_hours(saturday_score) + "h");
  $('td.total_6').text(minutes_to_hours(sunday_score) + "h");

  $('.daily_total_score').text(minutes_to_hours(total) + "h");
});

function minutes_to_hours(val){
  time = val / 60
  time = Number(time.toFixed(1))
  return String(time)
}