// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require('work_hours_targets.js')
require('work_hours_achivements.js')
require('monthly_work_hours_achivements.js')
require('daily_work_hours_achivements.js')
require('business_inventory_analyses.js')
require('kpi_files.js');