$(document).ready(function(){

  var monthly_man_hours = $('.monthly-man-hours').val();
  var others_ratio = $('.others_ratio').val();
  var total_number = 0;
  $('.work-hours-targets tr').each(function(index, elm){
    var number = "";
    var required_time = "";
    var total = 0;

    number        = $(this).children('td.number').find('input').val();
    required_time = $(this).children('td.required_time').find('input').val();

    if(number !== undefined){
      total = number * required_time
      total_number += total;
    }
  });

  $('.work-hours-targets tr').each(function(index, elm){
    var number = "";
    var required_time = "";
    var total = 0;

    number        = $(this).children('td.number').find('input').val();
    required_time = $(this).children('td.required_time').find('input').val();

    total = number * required_time
    total = Number(total) / 60
    total_average = total / monthly_man_hours * 100

    total_average = Math.round(total_average*10) / 10;
    if(isNaN(total_average)){
      total_average = 0;
    } else if(total_average == Infinity){
      total_average = 0;
    } else {
    }
    total = Number(total.toFixed(1));
    $(this).children('td.total').text(String(total) + "h");
    $(this).children('td.average').text(total_average + "%");
  });

  var total_target_number = 0;
  $('.total-work-hours-targets tr').each(function(index, elm){
    var number = "";
    number        = $(this).children('td.number').text();
    if(number !== ""){
      total_target_number += parseFloat(number)
    }
  });

  var monthly_man_hours = $('.monthly-man-hours').val();
  var others_number = 0;
  if(!others_ratio){
    others_ratio = 0;
  }
  others_number = monthly_man_hours / 100 * others_ratio
  $('.total-work-hours-targets tr').each(function(index, elm){
    var number = "";
    var others_total_number = 0;
    var others_total_average = 0;
    var total = 0;
    var others_average = 0;
    var monthly_man_hours_minutes = 0;
    var goukei_number = 0;

    number        = $(this).children('td.number').text();
    total_average = Number(number) / monthly_man_hours * 100
    total_average = Math.round(total_average);

    var difference = 0

    monthly_man_hours_minutes = Number(monthly_man_hours) * 60;

    difference = total_target_number - monthly_man_hours

    others_number_minutes = others_number;

    difference = Math.round(difference*100) / 100

    if(monthly_man_hours == "0"){
      others_number = "--"
      others_average = "--"
      others_total_number = "--" 
      others_total_average = 0 
    } else {
      others_total_number = monthly_man_hours / 100 * others_ratio
    }

    if(others_average == Infinity){ others_average = 0 }
    if(isNaN(others_average)){ others_average = 0 }
    if(total_average == Infinity){ total_average = 0 }
    if(isNaN(total_average)){ total_average = 0 }
    if(isNaN(others_number_minutes)){ others_number_minutes = 0 }

    $('.goal-number').text(total_target_number.toFixed(1));
    $('.difference').text(difference);
    $(this).children('td.total-number').text(total_target_number.toFixed(1));
    $(this).children('td.total-average').text(total_average + "%");

    $('.others-total-number').text(others_number);
    $('.others-total-average').text(others_ratio + "%");

    $('.others_total').text(others_number_minutes + "h");
    $('.others_average').text(others_ratio + "%");
  });

  var total = "";
  var total_ratio = "";
  total = total_target_number + others_number;

  total_ratio = total / monthly_man_hours * 100

  if(monthly_man_hours == "0"){
    $('.total_number').text(total_target_number.toFixed(1));
    $('.total_ratio').text("100%");
  } else {
    $('.total_number').text(total.toFixed(1));
    $('.total_ratio').text(total_ratio.toFixed(1) + "%");
  }


  $('select.level_one_process_category_id').on('change', function() {
 
   //ここに処理を記述する
 
    var select_val = "";
    select_val = $(this).val();

    var level_two_process_category = $("#level_two_process_category").val();
    level_two_process_category_parse = JSON.parse(level_two_process_category)


    /// jQueryによるセレクトボックス初期化
    var select = $(this).parent().next().children();
    select.children().remove();

    var keys = Object.keys(level_two_process_category_parse[select_val]);
    keys.forEach(function(key, i){
      /// option要素を動的に生成＆追加
      var content = this[key];
      var option = $('<option>')
        .text(content['name'])
        .val(content['id']);
      select.append(option);
    }, level_two_process_category_parse[select_val]);
  });
});