$(document).ready(function(){
  $(document).on('click', '.btn-kpi', function(){
		var uuid = $(this).data('uuid');
		var sheet_name = "";
		var selector = "";
    $('.sheet_name').each(function(index, element){
        tag_name = $(this).attr("name");
        if(~tag_name.indexOf(uuid)){
            selector = this
            sheet_name = $(this).val();
            return true;
        }
    });

		var postdata = { "sheet_name":sheet_name };

    $.ajax({
      type: "PATCH",
      url: "/kpi_files/" + uuid,
      data: postdata
    })
    .done(function(data) {
      $(selector).val(data["sheet_name"]);
    })
    .fail(function(){
    })
  });
  $('#kpi').sortable();
  $('#kpi').bind('sortstop',function(){
    $(this).find('[name="num_data"]').each(function(idx){
      $(this).html(idx+1);
      $(`#kpi_detail_${idx}priority`).val(idx+1);
    });


    var uuid = $("#kpi_files_uuid").val();
    var kpi_detail_ids = []; 
    $('#kpi tr').each(function(i, index){
      var id = $(index).find('input[type=hidden]').val();
      kpi_detail_ids.push(id)
    });

    var postdata = {kpi_detail_id: kpi_detail_ids}; 

    $.ajax({
      type: "POST",
      url: "/kpi_files/" + uuid + "/sort",
      data: postdata
    })
    .done(function(data) {
    })
    .fail(function(){
      console("Failure");
    })
  });


  var total_score = 0;
  $('#kpi tr').each(function(index , elm){
    //var weight = "";
    //var achivement = "";
    var score = "";

    weight = Number($(elm).find('textarea.weight').val());
    score = Number($(elm).find('select.score').val());

    score = weight * score / 6
    
    if(isNaN(score)){ score = 0 };
    total_score += score;
  });

  $('.total_score').text(total_score.toFixed(1));
});